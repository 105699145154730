


























import dispositivoModule from "@/store/modules/dispositivo-module";
import sensorModule from "@/store/modules/sensor-module";
import { Component, Vue } from "vue-property-decorator";
@Component({
  components: {
    DynamicTable: () => import("@/views/dynamic_tables/dynamic_table.vue"),
  },
})
export default class DispositivoFicha extends Vue {
  //Donde se tiene que ver los datos de los recursos con los dispositivos vinculados
  //sensores vinculados
  async created() {
    dispositivoModule.getdispositivo(this.$route.params.id);
    await sensorModule.getsensores_by_dispositivo(this.$route.params.id);
  }

  get data_source() {
    return dispositivoModule.dispositivo;
  }
  get data_source_sensores() {
    return sensorModule.sensores;
  }
}
